<template lang="">
    <Card>
        <template #header>
            Konum Bilgileri
        </template>
        <div class="row">
            <div class="col-md-6 col-12">
                <div class="form-group">
                    <label for="" class="form-label">İl</label>
                    <p>Batman</p>
                </div>
            </div>
            <div class="col-md-6 col-12">
                <div class="form-group">
                    <label for="" class="form-label">İlçe</label>
                    <p>Batman</p>
                </div>
            </div>
            <div class="col-md-6 col-12">
                <div class="form-group">
                    <label for="" class="form-label">Mahalle</label>
                    <p>AKOBA KÖYÜ, DEMİRCİ MEZRASI</p>
                </div>
            </div>
            <div class="col-md-6 col-12">
                <div class="form-group">
                    <label for="" class="form-label">Köy</label>
                    <p>Yeşiloba Köyü</p>
                </div>
            </div>
            <div class="col-md-6 col-12">
                <div class="form-group">
                    <label for="" class="form-label">Cadde</label>
                    <p>13</p>
                </div>
            </div>
            <div class="col-md-6 col-12">
                <div class="form-group">
                    <label for="" class="form-label">Sokak</label>
                    <p>-</p>
                </div>
            </div>
            <div class="col-md-6 col-12">
                <div class="form-group">
                    <label for="" class="form-label">Dış Kapı Numarası</label>
                    <p>21</p>
                </div>
            </div>
            <div class="col-md-6 col-12">
                <div class="form-group">
                    <label for="" class="form-label">İç Kapı Numarası</label>
                    <p>21</p>
                </div>
            </div>
            <div class="col-12">
                <div class="form-group">
                    <label for="" class="form-label">Adres</label>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus natus, eum doloribus veritatis rem facere libero repudiandae id quo nostrum!</p>
                </div>
            </div>
        </div>
        <div class="card-sub-header">Harita Koordinatı</div>
        <div class="row">
            <div class="col-6">
                <div class="form-group">
                    <label for="" class="form-label">Enlem</label>
                    <p>39.9965</p>
                </div>
            </div>
            <div class="col-6">
                <div class="form-group">
                    <label for="" class="form-label">Boylam</label>
                    <p>32.6260</p>
                </div>
            </div>
        </div>
    </Card>
</template>
<script>
export default {
    
}
</script>
<style lang="scss" scoped>
.card-sub-header {
  background-color: $dveb-divider;
  padding: 1rem;
  font-size: 1.2rem;
  font-weight: 500;
  margin: 1rem 0;
}
</style>